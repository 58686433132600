// @flow strict
import React, { useState } from 'react';
import moment from 'moment';
import { Link } from 'gatsby';
import styles from './FeedCard.module.scss';
import { Spring } from 'react-spring/renderprops';
import classNames from 'classnames';

const FeedCard = ({ index, edge, isVisible, hovering, setHovering }) => (
  <div
    onMouseEnter={() => setHovering(index)}
    onMouseLeave={() => setHovering(-1)}
    style={{ zIndex: hovering ? 11 : 10 - index }}
  >
    <Link to={'/blog' + edge.node.fields.slug}>
      <Spring
        config={(key) => (key === 'opacity' ? { delay: 200 * index } : 0)}
        to={{ opacity: isVisible ? 1 : 0, scale: hovering ? 1.1 : 1.0 }}
        key={edge.node.fields.slug}
      >
        {({ opacity, scale }) => (
          <div
            style={{ opacity, transform: `scale(${scale})` }}
            className={styles['feed__item']}
          >
            <div className={styles['feed__item-meta']}>
              <time
                className={styles['feed__item-meta-time']}
                dateTime={moment(edge.node.frontmatter.date).format(
                  'MMMM D, YYYY'
                )}
              >
                {moment(edge.node.frontmatter.date).format('MMMM YYYY')}
              </time>
              <span className={styles['feed__item-meta-divider']} />
              <span
                className={classNames(
                  styles['feed__item-meta-category'],
                  styles['feed__item-meta-category-link']
                )}
              >
                {edge.node.frontmatter.category}
              </span>
            </div>
            <h2
              className={classNames(
                styles['feed__item-title'],
                styles['feed__item-title-link']
              )}
            >
              {edge.node.frontmatter.title}
            </h2>
          </div>
        )}
      </Spring>
    </Link>
  </div>
);

const FeedCards = ({ edges, isVisible }: Props) => {
  const [hovering, setHovering] = useState();
  return (
    <>
      <div className={styles.recentPosts}>Recent Posts</div>
      <div className={styles['feed']}>
        {edges.map((edge, index) => (
          <FeedCard
            index={index}
            hovering={hovering === index}
            setHovering={setHovering}
            edge={edge}
            isVisible={isVisible}
          />
        ))}
      </div>
      <Link className={styles.allArticles} to={'/blog/'}>
        All Articles
      </Link>
    </>
  );
};

export default FeedCards;
