// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import styles from './FloatingDonate.module.scss';

const FloatingDonate = () => (
  <div className={styles.floatingContainer}>
    <div className={styles.floating}>
      Maggie is running for re-election
      <div className={styles.buttons}>
        <button
          onClick={() => {
            window.location.href = 'https://forms.gle/4HCLECfzz3pKZuHK9';
          }}
          className={styles.details}
        >
          Sign Up
        </button>
        <a href="https://maggie-burton-2021.square.site/product/donate/1">
          <button className="donate">Donate</button>
        </a>
        <Link to="blog/pages/donate-details">
          <button className={styles.details}>Details</button>
        </Link>
      </div>
    </div>
  </div>
);

export default FloatingDonate;
