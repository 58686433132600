// @flow strict
import { graphql } from 'gatsby';
import React from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import VisibilitySensor from 'react-visibility-sensor';
import FeedCard from '../components/FeedCard';
import Layout from '../components/Layout';
import WelcomeSection, {
  SectionGrow,
  SectionShrink,
} from '../components/WelcomeSection';
import WelcomeStrip from '../components/WelcomeStrip';
import { useSiteMetadata } from '../hooks';
import type { AllMarkdownRemark, PageContext } from '../types';
import BottomBar from '../components/BottomBar/BottomBar';
import FloatingDonate from '../components/FloatingDonate';

type Props = {
  data: AllMarkdownRemark,
};

const IndexTemplate = ({ data }: Props) => {
  const {
    title: siteTitle,
    subtitle: siteSubtitle,
    isReelection,
  } = useSiteMetadata();

  const { edges } = data.allMarkdownRemark;

  return (
    <>
      {isReelection && <FloatingDonate />}
      <Layout title={siteTitle} description={siteSubtitle}>
        <WelcomeStrip />
        <WelcomeSection>
          <SectionShrink>
            <img src="/maggie-twitter.jpg" alt="Maggie in St. John's" />
          </SectionShrink>
          <SectionGrow>
            <h4>Read {siteTitle}'s Twitter</h4>
            <TwitterTimelineEmbed
              sourceType="timeline"
              screenName="mmburton"
              options={{ height: 400 }}
            />
          </SectionGrow>
        </WelcomeSection>
        <h4>Read {siteTitle}'s Blog</h4>
        <VisibilitySensor>
          {({ isVisible }) => <FeedCard edges={edges} isVisible={isVisible} />}
        </VisibilitySensor>
        <BottomBar />
      </Layout>
    </>
  );
};

export const query = graphql`
  query IndexTemplate($postsLimit: Int!, $postsOffset: Int!) {
    allMarkdownRemark(
      limit: $postsLimit
      skip: $postsOffset
      filter: { frontmatter: { template: { eq: "post" }, draft: { ne: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            categorySlug
          }
          frontmatter {
            title
            date
            category
          }
        }
      }
    }
  }
`;

export default IndexTemplate;
