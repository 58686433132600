import React from 'react';
import styles from './WelcomeSection.module.scss';

export default (props) => 
    <div className={styles['section']} {...props}/>

export const SectionGrow = (props) => 
    <div className={styles['grow']} {...props}/>

export const SectionShrink = (props) => 
    <div className={styles['shrink']} {...props}/>