import React from 'react';
import styles from './BottomBar.module.scss';
import { useSiteMetadata } from '../../hooks';

export default () => {
    const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();
    return (
        <>
            <hr className={styles['rule']}/>
            <div className={styles['container']}>
                <div className={styles['text']}>{siteTitle}</div>
                <div className={styles['text']}>{siteSubtitle}</div>
                <div className={styles['link']}><a href="https://mitchellhynes.com">Made by Mitchell</a></div>
            </div>
        </>
    );
}
