// @flow strict
import React from 'react';
import styles from './Author.module.scss';
import { subtitle } from '../../../../config';
import TextTruncate from 'react-text-truncate';
import { Link } from 'gatsby';

type Props = {
  author: {
    name: string,
    bio: string,
    photo: string,
  },
  isReelection: boolean,
  subtitle: string,
  children: ReactNode,
};

//NOTE: Renders author for: Welcoming Strip.
const Author = ({ author, children, isReelection }: Props) => (
  <div className={styles['author']}>
    <img
      src={'photo-welcome.jpg'}
      className={styles['author__photo']}
      alt={author.name}
    />

    <span className={styles['author__title']}>
      <div className={styles['author__logo']}>
        <img alt="Maggie Burton Logo" src="./maggie-logo.svg" />
      </div>
      <h4 className={styles['author__subtitle']}>{subtitle}</h4>
      {isReelection && (
        <Link to="/blog/posts/Re-election%202021">
          <h4 className={styles['author__reelection']}>Seeking Re-election➜</h4>
        </Link>
      )}
    </span>
    <TextTruncate
      line={8}
      truncateText="…"
      text={author.bio}
      textTruncateChild={<Link to={'blog/pages/about'}>Read More</Link>}
    />
    {children}
  </div>
);

export default Author;
