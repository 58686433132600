// @flow strict
import React from 'react';
import Author from './Author';
import Contacts from './Contacts';
import Copyright from './Copyright';
import Menu from './Menu';
import styles from './WelcomeStrip.module.scss';
import { useSiteMetadata } from '../../hooks';

const WelcomeStrip = () => {
  const { author, copyright, menu, isReelection } = useSiteMetadata();

  return (
    <div className={styles['welcome']}>
      <div className={styles['welcome__inner']}>
        <Author author={author} isReelection={isReelection}>
          <Menu menu={menu} />
        </Author>
        <Contacts contacts={author.contacts} />
        <Copyright copyright={copyright} />
      </div>
    </div>
  );
};

export default WelcomeStrip;
