'use strict';

module.exports = {
  url: 'https://maggieburton.com',
  pathPrefix: '/',
  title: 'Maggie Burton',
  subtitle: 'Councillor at Large, City of St. John’s',
  copyright: '',
  disqusShortname: 'mitchellhynes',
  postsPerPage: 4,
  googleAnalyticsId: 'G-Z2DCCGTFF3',
  isReelection: false,
  menu: [
    {
      label: 'Articles',
      path: '/blog',
    },
    {
      label: 'My Track Record',
      path: '/blog/pages/track-record',
    },
    {
      label: 'My Platform',
      path: '/platform',
    },
    {
      label: 'About me',
      path: '/blog/pages/about',
    },
    {
      label: 'Contact me',
      path: '/blog/pages/contacts',
    },
  ],
  author: {
    name: 'Maggie Burton',
    photo: '/photo.jpg',
    bio: `I believe in a St. John’s where residents feel connected to each other and to the place they call home regardless of age, ability, income, race, or gender. We face a lot of challenges as a city: a sprawling car-centric urban fabric, a harsh climate, an aging population, an uncertain provincial economy. Now more than ever, municipal politics has both immediate and long-term impacts on the day-to-day lives of Canadians as we grapple with the effects, both present and future, of the climate, economic, and social crises of our times. I believe that we can work together to improve the place we call home to build a healthy, sustainable, and just city that works for the residents of St. John’s.
    Currently, my portfolios are Planning and Development, Heritage, Youth, Audit, and Animal Care and Control. I serve as a City representative on the St. John’s Urban Indigenous Coalition. I am an ex-officio board member for the <a href="http://www.naacap.ca/">Northeast Avalon Atlantic Coastal Action Program</a> and a board member for <a href="https://www.thechildrenscentre.ca/">The Children’s Centre.</a> I co-chair the St. John’s Food Policy Council.`,
    contacts: {
      email: '',
      telegram: '',
      rss: '',
      vkontakte: '',
      line: '',
      gitlab: '',
      github: '',
      weibo: '',
      codepen: '',
      youtube: '',
      soundcloud: '',
      facebook: 'maggieatlarge',
      twitter: 'mmburton',
      linkedin: 'maggieburton',
      instagram: 'mmburtonn',
    },
  },
};
